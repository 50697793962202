import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import Keycloak from 'keycloak-js';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import process from 'process';
import {
  requestGQL,
  requestGQLWithoutSchema,
} from '../functions/request.function';
import { exchangeTokenGql } from '../gql/exchangeToken.gql';
import { RootStoreMobX } from './root.store';

const CookiesConf = Cookies.withAttributes({
  expires: 30, //days
  path: '/',
  domain: process.env.REACT_APP_ENV === 'local' ? undefined : '.yoonite.tech',
});

type Account = {
  _id: string;
  email: string;
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

export type SSOContextType = {
  mode: { type: 'children'; parentUrl: string } | { type: 'parent' } | null;
  instance: Keycloak | null;
  account?: Account | null;
  user?: unknown;
};

const localStorageMapper = {
  refreshToken: 'kcRefreshToken',
  token: 'kcToken',
  tokenExchange: 'yooniteToken',
};

export class SSOStoreMobX {
  root: RootStoreMobX;
  @observable mode: SSOContextType['mode'] = null;
  @observable instance: SSOContextType['instance'] = null;
  @observable user: SSOContextType['user'] = null;
  @observable load: boolean = false;
  @observable account: Account | null = null;
  @observable roles: Array<string> = [];
  @observable authenticated: boolean = false;

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  @action public login() {
    if (this.instance) {
      this.instance
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          scope: 'openid email profile roles',
        })
        .then((auth) => {
          if (auth && this.instance?.token && this.instance?.refreshToken) {
            this.connect({
              token: this.instance.token,
              refreshToken: this.instance.refreshToken,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.logout();
        });
    }
  }

  @action public restoreSession() {
    return this.instance
      ?.init({
        onLoad: 'check-sso',
        token: CookiesConf.get(localStorageMapper.token!),
        refreshToken: CookiesConf.get(localStorageMapper.refreshToken!),
      })
      .then((auth) => {
        if (auth && this.instance?.token && this.instance?.refreshToken) {
          this.connect({
            token: this.instance.token,
            refreshToken: this.instance.refreshToken,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.logout();
      });
  }

  @action public logout() {
    this.disconnect();
    return this.instance?.logout({ redirectUri: window.location.origin });
  }

  @action private connect({
    token,
    refreshToken,
  }: {
    token: string;
    refreshToken: string;
  }) {
    CookiesConf.set(localStorageMapper.token, token);
    CookiesConf.set(localStorageMapper.refreshToken, refreshToken);
    this.auth();
  }

  @action private disconnect() {
    CookiesConf.remove(localStorageMapper.token);
    CookiesConf.remove(localStorageMapper.refreshToken);
    CookiesConf.remove(localStorageMapper.tokenExchange);
    this.user = null;
    this.authenticated = false;
  }

  @action private removeTokenExchange() {
    CookiesConf.remove(localStorageMapper.tokenExchange);
    this.account = null;
    this.roles = [];
    localStorage.removeItem('token');
    this.authenticated = false;
  }

  @action private setTokenExchange(token: string) {
    CookiesConf.set(localStorageMapper.tokenExchange, token);

    const { account, roles } = jwtDecode<{
      account: Account;
      tokenSSO: string;
      roles: Array<string>;
    }>(token);

    this.account = account;
    this.roles = roles;
    localStorage.setItem('token', token);
    this.authenticated = true;
  }

  @observable private get tokenSSO() {
    return CookiesConf.get(localStorageMapper.token!);
  }

  @action public init({
    mode,
    instance,
  }: Pick<SSOContextType, 'mode' | 'instance'>) {
    runInAction(() => {
      this.load = false;
      this.mode = mode;
      this.instance = instance;
    });

    if (this.mode?.type === 'parent') {
      if (this.instance?.authenticated && this.authenticated) {
        this.restoreSession();
      } else {
        this.login();
      }
    } else {
      this.auth();
    }
    this.load = true;
  }

  @action public async auth() {
    switch (this.mode?.type) {
      case 'parent':
        try {
          const { token } = await requestGQL({
            operationName: 'exchangeToken',
            params: { token: this.tokenSSO },
            gql: exchangeTokenGql,
          });

          this.setTokenExchange(token);
        } catch (error) {
          this.logout();
          this.removeTokenExchange();
        }

        break;

      case 'children':
        try {
          this.setTokenExchange(
            CookiesConf.get(localStorageMapper.tokenExchange)!,
          );

          const me = await requestGQLWithoutSchema({
            operationName: 'me',
            operationType: 'QUERY',
          });

          this.user = me;
        } catch (e) {
          console.log({ e });
          this.removeTokenExchange();
          this.user = null;
          window.location.replace(this.mode.parentUrl);
        }

        break;
    }
  }
}
