import {
  Form as FormAnt,
  FormListFieldData as FormListFieldDataAnt,
} from 'antd';
import { Rule as RuleAnt } from 'antd/es/form';
import type { FormInstance as FormInstanceAnt } from 'antd/lib';
import { FormListOperation as FormListOperationAnt } from 'antd/lib';
import { FormListProps as FormListPropsAnt } from 'antd/lib/form';
import equal from 'deep-equal';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import type {
  Color,
  Layout as LayoutGlobal,
  Placement2Axis,
  Size,
  Way as WayGlobal,
} from '../../types/component.type';
import type { AutoCompleteType } from '../autocomplete/autocomplete.component';
import type { CheckboxType } from '../checkbox/checkbox.component';
import type { DatePickerType } from '../datepicker/datepicker.component';
import type { InputType } from '../input/input.component';
import { RadioGroupType } from '../radio/radio.component';
import type { SelectType } from '../select/select.component';
import { SwitchType } from '../switch/switch.component';
import type { TextAreaType } from '../textarea/textarea.component';
import type { UploadType } from '../upload/upload.component';
import './form.component.scss';

export declare namespace FormType {
  type Props = {
    className?: string;
    handleEvent?: {
      instance?: FormType.HandleEvent.Instance['function'];
      validate?: (value: boolean) => void;
      change?: (value: FormType.Data.Value) => void;
      submit?: (value: FormType.Data.Value) => void;
      // clear?: (value: boolean) => void;
    };
    config?: {
      validateOnChange?: boolean;
      element?: {
        width?: FormType.Config.Element.Width;
        height?: FormType.Config.Element.Height;
        way?: FormType.Config.Element.Way;
      };
    };
    data?: {
      value?: FormType.Data.Value;
      defaultValue?: FormType.Data.Value;
    };
    children: React.ReactNode;
  };

  namespace Data {
    type Value = any;
  }

  namespace Config {
    namespace Element {
      type Width = Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;

      type Height = Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;

      type Way = FormItemType.Config.Way;
    }
  }

  namespace HandleEvent {
    type Instance = {
      value1: FormInstanceAnt<FormType.Data.Value>;
      return: void;
      function: (
        value: FormType.HandleEvent.Instance['value1'],
      ) => FormType.HandleEvent.Instance['return'];
    };
  }
}

export const Form = ({
  handleEvent: {
    instance,
    validate,
    change,
    submit,
    // clear = undefined,
  } = {},
  children,
  config: { validateOnChange = false, element = {} } = {},
  className = '',
  data: { value, defaultValue } = {},
}: FormType.Props) => {
  type FieldData = {
    errors: string[];
    name: string[];
    touched: boolean;
    validated: boolean;
    validating: boolean;
    value: string | undefined;
    warnings: string[];
    originRCField: boolean;
  };

  const [form] = FormAnt.useForm();
  const [isValid, setValid] = useState(false);
  const [shallowValues, setRefresh] = useState<Object>({});

  useEffect(() => {
    instance && instance(form);
  }, []);

  useEffect(() => {
    form.setFieldsValue(value);
    if (value && !equal(value, shallowValues)) {
      setRefresh(shallowValues);
    }
  }, [value, shallowValues]);

  const refreshValidation = useCallback(() => {
    form
      .validateFields({ validateOnly: true, dirty: false, recursive: true })
      .then(() => setValid(true))
      .catch(() => setValid(false));
  }, []);

  useEffect(() => refreshValidation(), [shallowValues]);

  useEffect(() => {
    validate && validate(isValid);
  }, [shallowValues, isValid]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const renderData = useCallback((data: FieldData[]) => {
    return data.reduce(
      (acc: Record<string, any>, { name, value }: FieldData) => {
        const formattedValue = value;

        //* Form Item
        if (name?.length === 1) {
          const [specificName] = name;
          return {
            ...acc,
            [specificName]: formattedValue,
          };
        }

        //* Form List
        if (name?.length === 3) {
          if (!value) return acc;

          const [globalName, index, specificName] = name;

          const newAcc = { ...acc };

          if (!acc?.[globalName]) {
            newAcc[globalName] = [];
            newAcc[globalName][index] = { [specificName]: formattedValue };
            return newAcc;
          }

          if (!acc?.[globalName]?.[index]) {
            newAcc[globalName][index] = { [specificName]: formattedValue };
            return newAcc;
          }

          if (!acc?.[globalName]?.[index]?.[specificName]) {
            newAcc[globalName][index] = {
              ...newAcc[globalName][index],
              [specificName]: formattedValue,
            };
            return newAcc;
          }

          if (acc?.[globalName]?.[index]?.[specificName]) {
            newAcc[globalName][index][specificName] = formattedValue;
            return newAcc;
          }
        }

        console.error('Y a un cas non géré dans FORM COMPONENT RENDER DATA');
        return acc;
      },
      {},
    );
  }, []);

  return (
    <Form.Context.Provider
      value={{
        width: element?.width,
        height: element?.height,
        way: element?.way,
      }}
    >
      <FormAnt
        form={form}
        preserve={true}
        initialValues={value ?? defaultValue ?? {}}
        onFieldsChange={(changedFields, allFields) => {
          if (!change) return;
          const data = renderData(allFields as FieldData[]);

          // onChange event
          change && change(data);

          // validate event
          if (validateOnChange) {
            const values = data;

            if (!equal(values, shallowValues)) setRefresh(values);
          }
        }}
        onFinish={(params) => submit && submit(params)}
        className={`form`}
      >
        <div className={`form__contain ${className}`}>{children}</div>
      </FormAnt>
    </Form.Context.Provider>
  );
};

Form.Context = createContext<{
  width?: FormType.Config.Element.Width;
  height?: FormType.Config.Element.Height;
  way?: FormType.Config.Element.Way;
}>({});

export declare namespace FormListType {
  type Props = {
    children: FormListType.Children;
    data?: {
      defaultValues?: Object[];
    };
    config: {
      name: string;
      rules?: FormListType.Config.Rules;
    };
  };

  type Children = (
    fields: FormListFieldDataAnt[],
    operations: FormListOperationAnt,
    meta: {
      errors: React.ReactNode[];
      warnings: React.ReactNode[];
    },
  ) => React.ReactNode;

  namespace Config {
    type Rules = FormListPropsAnt['rules'];
  }
}

Form.List = ({
  children,
  data: { defaultValues } = {},
  config: { name, rules = [] },
  ...form
}: FormListType.Props) => {
  return (
    <FormAnt.List
      name={name}
      initialValue={defaultValues}
      rules={rules}
      {...form}
    >
      {children}
    </FormAnt.List>
  );
};

export declare namespace FormItemType {
  type Props = {
    className?: string;
    handleEvent?: {};
    children: React.ReactElement<
      | AutoCompleteType.Props
      | CheckboxType.Props
      | DatePickerType.Props
      | InputType.Props
      | SelectType.Props
      | TextAreaType.Props
      | UploadType.Props
      | SwitchType.Props
      | RadioGroupType.Props
    >;
    data?: {
      defaultValue?: string | number | boolean | null;
    };
    config: {
      name: string;
      label?: string;
      rules?: FormItemType.Config.Rule[];
      way?: FormItemType.Config.Way;
      defaultValue?: string;
      tooltip?: string | React.ReactNode;
      color?: Extract<Color, 'yin' | 'yang' | 'white' | 'black' | 'label'>;
      labelLimitation?: boolean;
    };
  };

  type Legacy<T> = T & {
    value?: any;
    onChange?: Function;
  };

  namespace Config {
    type Rule = {
      required?: boolean;
      message?: string;
      min?: number;
      max?: number;
      len?: number;
      warningOnly?: boolean;
      pattern?: RegExp;
      whitespace?: boolean;
      validator?: FormItemType.Config.Validator['function'];
    };

    type Validator = {
      value1: RuleAnt;
      value2: any;
      return: Promise<Error | string | boolean | void>;
      function: (
        rules: FormItemType.Config.Validator['value1'],
        value: FormItemType.Config.Validator['value2'],
      ) => FormItemType.Config.Validator['return'];
    };

    type Way = Extract<WayGlobal, 'vertical' | 'horizontal'>;
  }
}

Form.Item = ({
  className = '',
  handleEvent = {},
  children,
  data: { defaultValue = undefined } = {},
  config: {
    tooltip,
    name,
    label,
    rules = [],
    way = 'horizontal',
    color = 'label',
    labelLimitation = false,
  },
  ...formParams
}: FormItemType.Props) => {
  const { t, lang } = useTranslation();

  return (
    <Form.Context.Consumer>
      {(form) => {
        return (
          <FormAnt.Item
            initialValue={defaultValue}
            label={label}
            name={name}
            rules={rules.map((rule: FormItemType.Config.Rule) => {
              if (!('validator' in rule)) {
                return rule;
              } else {
                const { validator, ...rest } = rule;
                return {
                  ...rest,
                  validator: (
                    value1: FormItemType.Config.Validator['value1'],
                    value2: FormItemType.Config.Validator['value2'],
                  ) => {
                    return new Promise((resolve, reject) => {
                      validator?.(value1, value2)
                        .then((res) => resolve(res))
                        .catch((error: unknown) => {
                          if (error instanceof Error) {
                            reject(new Error(t(error.message)));
                          } else {
                            reject(error);
                          }
                        });
                    });
                  },
                };
              }
            })}
            colon={false}
            tooltip={tooltip}
            className={`
              ${className} 
              formitem
              formitem--color--${color} 
              ${labelLimitation ? 'formitem--labelLimitation' : ''}
              formitem--${form.way || way}
            `}
            preserve={false}
            {...formParams}
          >
            {children}
          </FormAnt.Item>
        );
      }}
    </Form.Context.Consumer>
  );
};

export declare namespace FormStructureType {
  type Props = {
    children: React.ReactNode | React.ReactNode[];
    config?: {
      layout?: Extract<LayoutGlobal, 'column' | 'row'>;
      align?: Extract<Placement2Axis, 'left' | 'center' | 'right'>;
      width?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      fill?: boolean;
    };
  };
}

Form.Structure = ({
  children,
  config: { layout = 'row', align = 'left', width = 'full', fill = false } = {},
}: FormStructureType.Props) => {
  const style: React.CSSProperties = {
    alignItems: align,
  };

  const childrenFormatted = useCallback(
    (fill: boolean) => {
      const normalizeChildren = Array.isArray(children) ? children : [children];
      return normalizeChildren
        .filter((child) => child !== false)
        .map((element: React.ReactNode, index) => {
          return (
            <div
              key={index}
              className={`formstructure__contain__unit ${
                fill ? 'formstructure__contain__unit--fill' : ''
              }`}
            >
              {element}
            </div>
          );
        });
    },
    [children],
  );

  return (
    <div className="formstructure">
      <div
        style={style}
        className={`
          formstructure__contain 
          formstructure__contain--width--${width}
          formstructure__contain--layout--${layout}
        `}
      >
        {childrenFormatted(fill)}
      </div>
    </div>
  );
};
