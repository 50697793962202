import process from 'process';

export const apps: Array<{
  name: string;
  label: string;
  description: string;
  icon: string;
  url: { open: string };
}> = [
  {
    name: 'prestoscan',
    label: 'PrestoScan',
    description: 'A service to help with verbalization',
    icon: 'https://presto-scan-dev.yoonite.tech/favicon.svg',
    url: { open: process.env.REACT_APP_HOST_FRONT_PRESTO_SCAN_FAKE },
  },
  {
    name: 'admin',
    label: 'Admin',
    description: 'A service for IEM Support',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: process.env.REACT_APP_HOST_FRONT_ADMIN },
  },
  {
    name: 'module_equipment',
    label: 'Module Équipement',
    description:
      'Tool for managing and tracking equipment inventory and maintenance',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: process.env.REACT_APP_HOST_MODULE_EQUIPMENT },
  },
  {
    name: 'module_price_and_sale',
    label: 'Module Tarif/Vente',
    description:
      'Module for managing product pricing, promotions, and sales operations',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: 'https://www.iemgroup.com/' },
  },
  {
    name: 'module_finance',
    label: 'Module Finance',
    description:
      'System for managing financial operations, budgeting, and reporting',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: process.env.REACT_APP_HOST_MODULE_FINANCE },
  },
];
